cei.shared.cart = (function ($) {
    /**
     * @description Adds an item with the specified SKU code and quantity to the user's cart.
     * @param {string} skuCode - The product variant's code
     * @param {number} quantity - The quantity to be added
     * @param {string} alias - Combination of a catalog number and an item number
     * @param {string} code - The product code for personalization (optional - for personalized products)
     * @param {array} messages - The personalization message objects input by the user (optional - for personalized products)
     */
    function addToCart(skuCode, quantity, alias, code, messages) {
        var viewModel = {
            SkuCode: skuCode,
            Quantity: quantity,
            Alias: alias
        };

        if (typeof code !== "undefined" && typeof messages !== "undefined") {
            viewModel.PersonalizationCode = code;
            viewModel.PersonalizationMessages = messages;
        }

        return $.ajax({
            method: "POST",
            contentType: "application/json; charset=utf-8",
            data: JSON.stringify(viewModel),
            dataType: "json",
            url: '/api/CartItem',
            headers: { "__RequestVerificationToken": $("input[name='__RequestVerificationToken']").val() }
        });
    }

    /**
     * Applies the specified promo code to the user's cart.
     * @param {string} code - The promo code to apply
     * @returns {Object) - jqXHR object
     */
    function applyPromoCode(code) {
        return $.ajax({
            method: "POST",
            contentType: "application/json; charset=utf-8",
            dataType: "json",
            url: '/api/Cart/ApplyPromoCode/' + code
        });
    }

    /**
     * @description Gets the user's cart if it exists.
     * @returns {Object) - jqXHR object
     */
    function getCart() {
        return $.ajax({
            cache: false,
            contentType: "application/json; charset=utf-8",
            dataType: "json",
            method: "GET",
            url: "/api/Cart"
        });
    }

    /**
     * @description Deletes any items with the specified SKU code from the user's cart.
     * @param {string} skuCode - The product variant's code
     * @returns {Object) - jqXHR object
     */
    function deleteItem(skuCode) {
        return $.ajax({
            method: "DELETE",
            contentType: "application/json; charset=utf-8",
            dataType: "json",
            url: '/api/CartItem/' + skuCode,
            headers: { "__RequestVerificationToken": $("input[name='__RequestVerificationToken']").val() }
        });
    }

    /**
     * @description Removes the specified promo code from the user's cart.
     * @returns {Object) - jqXHR object
     */
    function removePromoCode() {
        return $.ajax({
            method: "POST",
            contentType: "application/json; charset=utf-8",
            dataType: "json",
            url: '/api/Cart/RemovePromoCode'
        });
    }

    /**
     * @description Gets the Cart Item Count and modifies the Cart Icon in the Default Header. 
     */
    function updateCartHeaderQuantity() {
        $.ajax({
            cache: false,
            contentType: "application/json; charset=utf-8",
            dataType: "json",
            method: "GET",
            url: "/api/Cart/ItemCount"
        }).done(function (data) {
            var countElement = $('.cart-item-count strong');
            var countLabelElement = $('.cart-item-count-label');
            var label = 'items';
            
            // data can be equal to 0 or more cart items
            if (typeof data !== 'undefined' && data !== null) {
                if (countElement.length) {
                    countElement.text(data);
                }
                if (countLabelElement.length) {
                    if (typeof data !== 'number') parseInt(data, 10);
                    if (data === 1) label = 'item';
                    countLabelElement.text(label);
                }
            }
        }).fail(function(jqXHR) {
            console.error('Status:',jqXHR.status,jqXHR.statusText);
        });
    }

    /**
     * @description Updates an item in the user's cart, setting the quantity to the value specified.
     * @param {string} skuCode - The product variant's code
     * @param {number} quantity - The item's new quantity
     * @returns {Object) - jqXHR object
     */
    function updateItemQuantity(skuCode, quantity) {
        var model = {
            skuCode: skuCode,
            quantity: quantity
        };

        return $.ajax({
            type: "PUT",
            cache: false,
            contentType: "application/json; charset=utf-8",
            data: JSON.stringify(model),
            dataType: "json",
            url: '/api/CartItem/' + skuCode,
            headers: { "__RequestVerificationToken": $("input[name='__RequestVerificationToken']").val() }
        });
    }

    return {
        addToCart: addToCart,
        applyPromoCode: applyPromoCode,
        deleteItem: deleteItem,
        getCart: getCart,
        removePromoCode: removePromoCode,
        updateCartHeaderQuantity: updateCartHeaderQuantity,
        updateItemQuantity: updateItemQuantity
    };

})(window.jQuery);